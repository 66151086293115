import {DataProvider, HttpError, Identifier} from "react-admin";
import provider from "ra-data-simple-rest";
import {API_URL} from "shared/constants/apiURL";
import axios from "axios";
import {HTTP_403_FORBIDDEN} from "shared/constants/ROUTES";
import {Auth} from "shared/services/auth";
import {getParams, getTotal} from "../utils/helpers";

export const withCatchAndLogout = async (callback: () => Promise<any>) => {
  try {
    callback();
  } catch (e) {
    if (e.response.status === HTTP_403_FORBIDDEN) {
      // await Auth.logout();
      // window.location.href = "/";
    }
  }
};

export function defaultProvider() {
  const api = API_URL;
  const prov = provider(api);

  return {
    ...prov,
    getList: async (resource = "", params) => {
      try {
        const {data = [], headers = {}} = await axios.get(
          `${api}/${resource}?${getParams(params)}`,
        );
        const total = data?.totalCount || getTotal(headers);

        return {data: data?.items || data, total};
      } catch (e) {
        if (e.response.status === HTTP_403_FORBIDDEN) {
          // await Auth.logout();
          // window.location.href = "/";
          throw new HttpError(
            e?.response?.data?.detail || "Отсутствует авторизация",
            e.response.status,
            {total: 0, data: []},
          );
        }

        return {total: 0, data: []};
      }
    },
    getOne: async (resource = "", params) => {
      try {
        const data = await axios.get(`${api}/${resource}/${params?.id}`);

        return {data: {...data.data}};
      } catch (e) {
        if (e.response.status === HTTP_403_FORBIDDEN) {
          // await Auth.logout();
          // window.location.href = "/";
          throw new HttpError(
            e?.response?.data?.detail || "Отсутствует авторизация",
            e.response.status,
            {total: 0, data: []},
          );
        }

        return {data: {id: null}};
      }
    },
    delete: async (resource = "", params) => {
      try {
        const res = await axios(`${api}/${resource}/${params.id}`, {
          method: "DELETE",
        });

        return res;
      } catch (e) {
        return Promise.reject(e);
      }
    },

    deleteMany: async (resource = "", params) => {
      const ids: Identifier[] = [];
      try {
        for (const id of params.ids) {
          await axios(`${API_URL}/${resource}/${id}`, {
            method: "DELETE",
            headers: {"Content-Type": "multipart/form-data"},
          });
          ids.push(id);
        }
        return {data: ids};
      } catch (e) {
        console.log(e);
        return {data: ids};
      }
    },
  } as DataProvider;
}
