import {SalesCompanies} from "./tabs/SalesCompanies";
import {SalesUsers} from "./tabs/SalesUsers";
import {Tab, Tabs} from "@mui/material";
import React from "react";
import {Link, Navigate, Route, Routes, useLocation} from "react-router-dom";
import {PERMISSIONS_FOR_ROUTES, ROUTES} from "shared/constants/ROUTES";
import {useAdminAuth} from "shared/hooks/adminAuthContext/useAdminAuth";
import {usePermission} from "shared/hooks/usePermission";

export const SalesCab = () => {
  const {pathname} = useLocation();
  const {user} = useAdminAuth();
  const {getRoutePermission} = usePermission();
  const handleRoutesPermission = React.useCallback(
    (name: keyof typeof PERMISSIONS_FOR_ROUTES): boolean =>
      getRoutePermission(name, user),
    [user, getRoutePermission],
  );

  return (
    <>
      <Tabs
        value={pathname}
        orientation="horizontal"
        aria-label="tab navigation"
        sx={{mb: 2}}
      >
        <Tab
          label="Компании"
          component={Link}
          to="companies"
          value={`/${ROUTES.SALES_CABINET.path}/companies`}
        />
        {handleRoutesPermission(`${ROUTES.SALES_CABINET.path}/users`) && (
          <Tab
            label="Пользователи"
            component={Link}
            to="users"
            value={`/${ROUTES.SALES_CABINET.path}/users`}
          />
        )}
      </Tabs>

      <Routes>
        <Route index element={<Navigate to="companies" />} />
        <Route path="/companies" element={<SalesCompanies />} />
        {handleRoutesPermission(`${ROUTES.SALES_CABINET.path}/users`) && (
          <Route path="/users" element={<SalesUsers />} />
        )}
      </Routes>
    </>
  );
};
