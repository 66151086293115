import {TextField as MUITextField, Stack, Button, FormHelperText} from "@mui/material";
import axios from "axios";
import {useState} from "react";
import {
  BooleanInput,
  DateInput,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import {API_URL} from "shared/constants/apiURL";

const DEFAULT_COMMENT_ERROR = {
  companyId: "",
  userId: "",
  internalComment: "",
  needAction: "",
  needActionDate: "",
  form: "",
};

type CommentType = {
  companyId?: string;
  userId?: string;
  internalComment: string | null;
  needAction: boolean | null;
  needActionDate: string | null;
};

const handleUpdateCommentBySales = async ({comments}: {comments: CommentType}) => {
  const fd = Object.entries(comments).reduce((formData, [key, value]) => {
    formData.append(key, `${value ?? ""}`);
    return formData;
  }, new FormData());
  await axios.post(`${API_URL}/company/v2/admin/update-company-sales-helper`, fd);
};

export const CommentSection = ({commentKey}: {commentKey: "userId" | "companyId"}) => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();

  const [isLoading, setIsLoading] = useState(false);
  const [comments, setComments] = useState<CommentType>({
    [commentKey]: record?.id as string,
    internalComment: record?.salesHelper?.internalComment,
    needAction: record?.salesHelper?.needAction,
    needActionDate: record?.salesHelper?.needActionDate,
  });
  const [error, setError] =
    useState<Record<keyof CommentType | "form", string>>(DEFAULT_COMMENT_ERROR);

  const handleSetComment = async () => {
    setIsLoading(true);
    try {
      await handleUpdateCommentBySales({comments});
      refresh();
      setError(DEFAULT_COMMENT_ERROR);
    } catch (err) {
      const detail = err.response.data.detail;
      setError(detail);
      notify("Произошла ошибка", {type: "error"});
      return;
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"flex-start"}
        alignItems={"flex-end"}
        sx={{width: "100%"}}
        gap={8}
      >
        <MUITextField
          fullWidth
          name={"internalComment"}
          label={"Комментарий"}
          multiline={true}
          rows={3}
          sx={{maxWidth: "600px"}}
          value={comments?.internalComment || ""}
          onChange={(e) =>
            setComments((prev) => ({...prev, internalComment: e.target.value}))
          }
          error={!!error.internalComment}
          helperText={error.internalComment}
        />

        <Stack direction={"row"} gap={4}>
          <DateInput
            source="salesHelper.needActionDate"
            label="Дата"
            onChange={(e) =>
              setComments((prev) => ({...prev, needActionDate: e.target.value}))
            }
            defaultValue={null}
            error={!!error.needActionDate}
            helperText={
              error.needActionDate ? (
                <FormHelperText error sx={{whiteSpace: "nowrap"}}>
                  {error?.needActionDate}
                </FormHelperText>
              ) : (
                ""
              )
            }
          />

          <BooleanInput
            source="salesHelper.needAction"
            label="Важно"
            onChange={(e) =>
              setComments((prev) => ({...prev, needAction: e.target.checked}))
            }
            helperText={false}
          />
        </Stack>

        <Button variant="main" onClick={handleSetComment} disabled={isLoading}>
          {record?.salesHelper ? "Изменить" : "Добавить"}
        </Button>
      </Stack>
      {error?.form && (
        <FormHelperText error sx={{width: "100%", textAlign: "right"}}>
          {error.form}
        </FormHelperText>
      )}
    </>
  );
};
