import {
  Tooltip,
  InputAdornment,
  Stack,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import {
  DateField,
  EmailField,
  List,
  TextField,
  useListContext,
  DatagridConfigurable,
  SelectColumnsButton,
  TopToolbar,
  TextInput,
  Labeled,
  minLength,
  FunctionField,
  WrapperField,
  Filter,
  SimpleForm,
} from "react-admin";
import Loader from "shared/components/Loader/Loader";
import {getDataGridStyles} from "shared/styles/dataGridStyles";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import {useEffect} from "react";
import {MyBooleanField} from "shared/components/MyBooleanField/MyBooleanField";
import {OPF_RECORD} from "shared/constants/OPFRecord";
import {CommentSection} from "../components/CommentSection";
import {UserLabelSection} from "../components/UserLabelSection";

const REGISTER_STATUSES = {
  NOT_REGISTERED: {color: "black500", label: "Компания не зарегистрирована"},
  INVOICE_NOT_REQUESTED: {color: "red", label: "Счёт не запрошен"},
  INVOICE_REQUESTED_NOT_PAID: {
    color: "orange",
    label: "Счёт запрошен, не оплачен",
  },
  ACTIVE_SUBSCRIPTION: {color: "green", label: "Активная подписка"},
  SUBSCRIPTION_EXPIRED: {color: "red600", label: "Подписка истекла"},
} as const;

type RegisterStatus = (typeof REGISTER_STATUSES)[keyof typeof REGISTER_STATUSES];

const PostListActions = () => (
  <TopToolbar>
    <SelectColumnsButton />
  </TopToolbar>
);

const CustomFilter = (props) => {
  const {setFilters, filterValues, displayedFilters, showFilter, total} =
    useListContext();

  const handleCheckboxChange = (id) => {
    const currentValues = Array.isArray(filterValues.hide) ? filterValues.hide : [];
    const newValues = currentValues.includes(id)
      ? currentValues.filter((value) => value !== id)
      : [...currentValues, id];

    setFilters({...filterValues, hide: newValues}, null, false);
  };

  useEffect(() => {
    if (!displayedFilters.hide) {
      showFilter("hide", ["withoutOurs"]);
    }
  }, [displayedFilters, showFilter]);

  return (
    <Filter {...props}>
      <TextInput
        source="search"
        resettable
        label={"Поиск"}
        alwaysOn
        validate={minLength(3)}
        InputProps={{
          endAdornment: (
            <InputAdornment position={"end"}>
              <Tooltip
                title={
                  <>
                    Поиск осуществляется при длине больше 3ех символов.
                    <br />
                    По частичному совпадению: ФИО, телефон, email, название компании.
                    <br />
                    По полному совпадению: ID пользователя, ИНН компании, КПП компании,
                    userId, companyId.
                  </>
                }
              >
                <LiveHelpIcon sx={{cursor: "help"}} />
              </Tooltip>
            </InputAdornment>
          ),
        }}
        sx={{maxWidth: "600px", width: "100%", minWidth: "500px"}}
      />

      <FunctionField
        source="hide"
        render={(record) => (
          <Box
            sx={{display: "flex", flexWrap: "wrap", my: 0, ml: 4, alignItems: "center"}}
            key="hide"
          >
            {[
              {id: "hasCompany", name: "C компанией"},
              {
                id: "noCompany",
                name: "Без компании",
              },
              {id: "withoutOurs", name: "Без своих"},
            ].map((choice) => {
              const isChecked =
                Array.isArray(filterValues.hide) && filterValues.hide.includes(choice.id);
              return (
                <FormControlLabel
                  key={choice.id}
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={() => handleCheckboxChange(choice.id)}
                      size="small"
                      sx={{height: "32px"}}
                    />
                  }
                  label={
                    <span>
                      {choice.name}{" "}
                      {isChecked ? (
                        <span
                          style={{
                            minWidth: "20px",
                            maxWidth: "20px",
                            display: "inline-block",
                          }}
                        >{`${total > 99 ? 99 : total || ""}`}</span>
                      ) : (
                        <span style={{width: "20px", display: "inline-block"}}></span>
                      )}
                    </span>
                  }
                />
              );
            })}
          </Box>
        )}
      />
    </Filter>
  );
};

export const SalesUsers = () => {
  return (
    <List
      filters={<CustomFilter />}
      sort={{field: "createdAt", order: "DESC"}}
      perPage={25}
      actions={<PostListActions />}
      sx={{
        ".RaFilterFormInput-hideButton": {display: "none"},
        ".RaList-actions": {minHeight: 48},
      }}
      resource="sales-cabinet/users"
    >
      <Fields />
    </List>
  );
};

function Fields() {
  const {isLoading = false} = useListContext();

  if (isLoading) return <Loader />;

  return (
    <DatagridConfigurable
      sx={({palette}) => ({
        ...getDataGridStyles(palette),
      })}
      bulkActionButtons={false}
      expand={<RowUserDetail />}
      expandSingle
      omit={[
        "department",
        "position",
        "role",
        "company.opf",
        "company.inn",
        "blockedInCompany",
        "company.srm_access",
        "updatedAt",
        "isDismissed",
      ]}
    >
      <TextField source="hid" label="ID" textAlign="center" />
      <TextField source="fio" label="ФИО" textAlign="left" />
      <TextField source="department" label="Отдел" textAlign="left" />
      <TextField source="position" label="Должность" textAlign="left" />
      <TextField source="role" label="Роль" sortable={false} textAlign="center" />
      <EmailField source="email" sx={{textDecoration: "none"}} textAlign="left" />
      <TextField source="phoneNumber" label="Телефон" textAlign="center" />
      <DateField source="createdAt" label="Регистрация" textAlign="center" />
      <DateField source="updatedAt" label="Обновлен" textAlign="center" />
      <WrapperField label="Статус регистрации">
        <FunctionField
          source={"company.invoices"}
          label={
            <>
              Статус <br /> регистрации
            </>
          }
          render={(record) => {
            const invoices = record?.company?.invoices || [];
            const subscriptions = record?.company?.subscriptions || [];

            let status: RegisterStatus | null = null;

            if (!record?.company) {
              status = REGISTER_STATUSES.NOT_REGISTERED;
            } else if (!invoices.length) {
              status = REGISTER_STATUSES.INVOICE_NOT_REQUESTED;
            } else if (subscriptions.length) {
              status = REGISTER_STATUSES.ACTIVE_SUBSCRIPTION;
            } else {
              const hasPaidInvoice = invoices.some((invoice) => invoice.isPaid);

              if (!hasPaidInvoice) {
                status = REGISTER_STATUSES.INVOICE_REQUESTED_NOT_PAID;
              } else if (!subscriptions.length && hasPaidInvoice) {
                status = REGISTER_STATUSES.SUBSCRIPTION_EXPIRED;
              }
            }

            return status ? (
              <Typography
                sx={({palette}) => ({
                  color: `${palette[`${status.color}`].main} !important`,
                })}
              >
                {status.label}
              </Typography>
            ) : null;
          }}
          textAlign="center"
        />
      </WrapperField>
      <FunctionField
        source={"company.opf"}
        label={"ОПФ"}
        render={(record) =>
          OPF_RECORD[record?.company?.opf]
            ? OPF_RECORD[record?.company?.opf]
            : record?.company?.opf
        }
        textAlign="center"
      />

      <WrapperField label="Название компании">
        <TextField
          source="company.name"
          label={
            <>
              Название <br /> компании
            </>
          }
          textAlign="left"
        />
      </WrapperField>

      <WrapperField label="ИНН Компании">
        <FunctionField
          source="company.inn"
          label={
            <>
              ИНН <br /> Компании
            </>
          }
          textAlign="center"
          render={(record) => {
            if (record?.proposedCompanyInn && !record.company) {
              return (
                <Typography color={"text.secondary"}>
                  {record.proposedCompanyInn}
                </Typography>
              );
            }
            return record?.company?.inn;
          }}
        />
      </WrapperField>

      <WrapperField label="Доступ в SRM">
        <MyBooleanField
          source="company.srm_access"
          label={
            <>
              Доступ <br /> в SRM
            </>
          }
          textAlign="center"
          FalseIcon={null}
        />
      </WrapperField>

      {/* Блокирован на ур-не ЭТП - обратное */}
      <WrapperField label="Блок площадкой">
        <MyBooleanField
          source="isActive"
          label={
            <>
              Блок <br /> площадкой
            </>
          }
          sortable={false}
          textAlign="center"
          FalseIcon={null}
        />
      </WrapperField>

      <WrapperField label="Блок компанией">
        <MyBooleanField
          source="blockedInCompany"
          label={
            <>
              Блок <br /> компанией
            </>
          }
          sortable={false}
          textAlign="center"
          FalseIcon={null}
        />
      </WrapperField>

      <MyBooleanField
        source="isDismissed"
        label="Уволен"
        sortable={false}
        textAlign="center"
        FalseIcon={null}
      />

      <FunctionField
        source="salesHelper"
        label="Комментарий"
        textAlign="left"
        sortable={false}
        render={(record) => {
          if (!record) return null;

          const {salesHelper, labels} = record;
          const internalComment = salesHelper?.internalComment;
          const needAction = salesHelper?.needAction;
          const needActionDate = salesHelper?.needActionDate;

          const formattedDate =
            needActionDate && !isNaN(new Date(needActionDate).getTime())
              ? new Date(needActionDate).toLocaleDateString()
              : null;

          return (
            <Box>
              {Array.isArray(labels) && labels.includes("Свой") && (
                <Typography
                  variant="body2"
                  sx={({palette}) => ({
                    color: palette.text.secondary,
                  })}
                >
                  Свой
                </Typography>
              )}

              {salesHelper && (
                <>
                  {internalComment && (
                    <Typography
                      variant="body2"
                      sx={({palette}) => ({
                        color: needAction
                          ? palette.orange?.main || "orange"
                          : palette.text.primary,
                      })}
                    >
                      {internalComment}
                    </Typography>
                  )}

                  {formattedDate && (
                    <Typography variant="body2" color="textSecondary">
                      {formattedDate}
                    </Typography>
                  )}
                </>
              )}
            </Box>
          );
        }}
      />
    </DatagridConfigurable>
  );
}

function RowUserDetail() {
  return (
    <>
      <SimpleForm
        sx={{p: 0, ">div": {gap: 3}, mt: 4, px: 8, width: "100%"}}
        toolbar={false}
      >
        <Stack direction={"row"} alignItems={"center"} gap={4}>
          <Stack direction={"row"} gap={2} alignItems={"center"}>
            <Typography sx={{fontWeight: 600}}>ФИО: </Typography>
            <TextField source="fio" label={"ФИО"} textAlign="center" />
          </Stack>

          <Stack direction={"row"} gap={2} alignItems={"center"}>
            <Typography sx={{fontWeight: 600}}>Email: </Typography>
            <EmailField source="email" textAlign="center" />
          </Stack>

          <Stack direction={"row"} gap={2} alignItems={"center"}>
            <Typography sx={{fontWeight: 600}}>Телефон: </Typography>
            <TextField source="phoneNumber" label={"Телефон"} textAlign="center" />
          </Stack>

          <UserLabelSection />
        </Stack>

        <Stack direction={"row"} gap={2} alignItems={"center"}>
          <Typography sx={{fontWeight: 600}}>Доступ в админку: </Typography>
          <FunctionField
            source="role"
            label="Доступ в админку"
            render={(record) => (record?.role === "Администратор" ? "Да" : "Нет")}
          />
        </Stack>

        <Stack direction={"row"} alignItems={"center"} gap={4}>
          <Stack direction={"row"} gap={2} alignItems={"center"}>
            <Typography sx={{fontWeight: 600}}>Дата регистрации: </Typography>
            <DateField source="createdAt" label="Дата регистрации" />
          </Stack>

          <Stack direction={"row"} gap={2} alignItems={"center"}>
            <Typography sx={{fontWeight: 600}}>Дата обновления профиля: </Typography>
            <DateField source="updatedAt" label="Дата обновления профиля" />
          </Stack>

          <Stack direction={"row"} gap={2} alignItems={"center"}>
            <Typography sx={{fontWeight: 600}}>Дата последней активности: </Typography>
            <DateField source="lastSeen" label="Дата последней активности" />
          </Stack>
        </Stack>
        <Stack direction={"row"} gap={2} alignItems={"center"}>
          <Typography sx={{fontWeight: 600}}>Компания: </Typography>
          <TextField source="company.name" label="Название компании" />
        </Stack>

        <Stack direction={"row"} spacing={3}>
          <Labeled label="Должность в компании">
            <TextField source="position" />
          </Labeled>
          <Labeled label="Отдел компании">
            <TextField source="department" />
          </Labeled>

          <Labeled label="Блок компанией">
            <MyBooleanField
              source="blockedInCompany"
              textAlign="center"
              FalseIcon={null}
            />
          </Labeled>
        </Stack>
        <Stack direction={"row"} spacing={3}>
          <Labeled label="Id компании">
            <TextField source="companyId" />
          </Labeled>
          <Labeled label="ОПФ компании">
            <TextField source="company.opf" />
          </Labeled>
          <Labeled label="ИНН компании">
            <TextField source="company.inn" />
          </Labeled>
          <Labeled label="КПП компании">
            <TextField source="company.kpp" />
          </Labeled>
        </Stack>
        <Stack direction={"row"} spacing={3}>
          <Labeled label="Заказчик">
            <MyBooleanField source="company.customer" FalseIcon={null} />
          </Labeled>

          <Labeled label="Поставщик">
            <MyBooleanField source="company.supplier" FalseIcon={null} />
          </Labeled>

          <Labeled label="Производитель">
            <MyBooleanField source="company.producer" />
          </Labeled>

          <Labeled label="Доступ в SRM">
            <MyBooleanField source="company.srmAccess" FalseIcon={null} />
          </Labeled>

          <Labeled label="Компания подтверждена">
            <MyBooleanField source="company.isVerified" />
          </Labeled>
        </Stack>

        <Stack direction={"row"} alignItems={"center"} gap={4}>
          <Stack direction={"row"} gap={2} alignItems={"center"}>
            <Typography sx={{fontWeight: 600}}>Уволен: </Typography>
            <FunctionField
              source="isDismissed"
              label="Уволен"
              render={(record) => (record?.isDismissed ? "Да" : "Нет")}
            />
          </Stack>

          <Stack direction={"row"} gap={2} alignItems={"center"}>
            <Typography sx={{fontWeight: 600}}>Блок площадкой: </Typography>
            <FunctionField
              source="isActive"
              label="Блок площадкой"
              render={(record) => (record?.isActive ? "Да" : "Нет")}
            />
          </Stack>
        </Stack>
        <Stack sx={{width: "100%", mt: 2, mb: 4, gap: 2}}>
          <CommentSection commentKey="userId" />
        </Stack>
      </SimpleForm>
    </>
  );
}
