import {DataProvider} from "react-admin";
import {defaultProvider} from "./defaultProvider";
import axios from "axios";
import {API_URL} from "shared/constants/apiURL";
import {
  handleConvertOneUserReqBody,
  handleConvertSalesUsersReqBody,
} from "../utils/helpers";
import {ROUTES} from "shared/constants/ROUTES";

export const salesUsersProvider = {
  ...defaultProvider(),
  getList: async (resource = "", params) => {
    const data = (
      await axios.post(
        `${API_URL}/${ROUTES.USERS.path}`,
        handleConvertSalesUsersReqBody(params),
      )
    )?.data;
    const total = data?.totalCount || 0;

    const dataWithBlockUsers = (data?.items || []).map((record) => ({
      ...record,
      isActive: !record.isActive,
      isDismissed: !!record.isDismissed,
    }));

    return {total, data: dataWithBlockUsers};
  },

  getOne: async (resource, params) => {
    const data = (
      await axios.post(
        `${API_URL}/${ROUTES.USERS.path}`,
        handleConvertOneUserReqBody(params),
      )
    )?.data;
    const user = data.items[0] || {};

    return {
      data: {
        id: params.id,
        permissions: user?.permissions || [],
        roles: user?.roles || {},
      },
    };
  },
} as DataProvider;
