import {DataProvider, HttpError} from "react-admin";
import {defaultProvider} from "./defaultProvider";
import axios from "axios";
import {API_URL} from "shared/constants/apiURL";
import {FATHER_ID} from "shared/constants/father";
import {COCK, UOCK} from "shared/constants/operatorKeys";
import {HTTP_403_FORBIDDEN} from "shared/constants/ROUTES";

export const fatherProvider = {
  ...defaultProvider(),
  getList: async (resource, params) => {
    try {
      const data = (await axios.post(`${API_URL}/${resource}`, {}))?.data;

      const updData = (data?.items || [])
        .map((item) => ({
          ...item,
          isActive: !item?.isActive,
        }))
        .filter((item) => item.id !== FATHER_ID);
      return {total: data?.total, data: updData};
    } catch (e) {
      if (e.response.status === HTTP_403_FORBIDDEN) {
        // await Auth.logout();
        // window.location.href = "/";
        throw new HttpError(
          e?.response?.data?.detail || "Отсутствует авторизация",
          e.response.status,
          {total: 0, data: []},
        );
      }

      return Promise.reject(e);
    }
  },

  getOne: async (resource, params) => {
    try {
      const data = (await axios.post(`${API_URL}/users/v2/r/lo`, {}))?.data;

      const updData = (data?.items || [])
        .map((item) => ({
          ...item,
          isActive: !item?.isActive,
        }))
        .filter((item) => item.id === params.id);
      return {data: updData[0]};
    } catch (e) {
      return Promise.reject(e);
    }
  },

  create: async (resource, params) => {
    try {
      const data = (
        await axios.post(`${API_URL}/users/v2/r/co`, {...params.data, value: COCK})
      )?.data;

      await axios.post(`${API_URL}/users/v2/r/uo`, {
        userId: data,
        value: UOCK,
        toAddIds: params?.data?.roles || [],
      });

      return {data: {id: data, ...params.data}};
    } catch (err) {
      const errBody = err.response.data.detail;
      throw new HttpError("Произошла ошибка", err.response.status, {errors: errBody});
    }
  },

  update: async (resource, params) => {
    try {
      const getRoleIds = (roles) =>
        roles.map((role) => (typeof role === "string" ? role : role.id));

      const previousRoles = getRoleIds(params?.previousData?.roles || []);
      const newRoles = getRoleIds(params?.data?.roles || []);

      const toAddIds = newRoles.filter((role) => !previousRoles.includes(role));
      const toRemoveIds = previousRoles.filter((role) => !newRoles.includes(role));

      await axios.post(`${API_URL}/users/v2/r/uo`, {
        userId: params.id,
        value: UOCK,
        toAddIds,
        toRemoveIds,
      });

      return {data: {...params.data}};
    } catch (err) {
      const errBody = err.response.data.detail;
      throw new HttpError("Произошла ошибка", err.response.status, {errors: errBody});
    }
  },
} as DataProvider;
