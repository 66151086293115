import {AxiosResponseHeaders} from "axios";
import qs from "qs";
import {GetListParams, GetOneParams} from "react-admin";
import {
  assetsListFieldForSorting,
  blocksFieldForSorting,
  companiesFieldForSorting,
  invoicesListFieldForSorting,
  promoListFieldForSorting,
} from "shared/constants/companyListHelpers";
import {usersFieldForSorting} from "shared/constants/usersListHelpers";
import {handleTransformToFormData} from "shared/utils/handleTransformObjToFD";
import queryString from "query-string";
import {object} from "prop-types";
/**
 * @returns {number} Возвращает общее количество всех доступных записей.
 */
export function getTotal(headers: AxiosResponseHeaders): number {
  const contentRange = headers["content-range"];
  const total = contentRange ? Number(contentRange.split("/").pop()) : 0;
  return total;
}

/**
 * Функция для генерации строки запроса на основе параметров.
 * @param {Object} params - Параметры запроса, содержащие информацию о пагинации, сортировке и фильтрации.
 * @returns {string} Возвращает строку запроса, сформированную на основе переданных параметров.
 */
export function getParams(params: any): string {
  const {page, perPage} = params.pagination;
  const {field, order} = params.sort;
  const rangeStart = (page - 1) * perPage;
  const rangeEnd = page * perPage - 1;

  const query = {
    sort: JSON.stringify([field, order]),
    range: JSON.stringify([rangeStart, rangeEnd]),
    filter: JSON.stringify(params.filter),
  };

  return qs.stringify(query);
}

const replaceSortForUsers = (field: string) => {
  return usersFieldForSorting[field] ? usersFieldForSorting[field] : field;
};

const replaceSortForCompanies = (field: string) => {
  return companiesFieldForSorting[field] ? companiesFieldForSorting[field] : field;
};

const replaceSortForBlocks = (field: string) => {
  return blocksFieldForSorting[field] ? blocksFieldForSorting[field] : field;
};

const replaceSortForPromoList = (field: string) => {
  return promoListFieldForSorting[field] ? promoListFieldForSorting[field] : field;
};

const replaceSortForAssetsList = (field: string) => {
  return assetsListFieldForSorting[field] ? assetsListFieldForSorting[field] : field;
};

const replaceSortForInvoicesList = (field: string) => {
  return invoicesListFieldForSorting[field] ? invoicesListFieldForSorting[field] : field;
};

/**
 * Функция для преобразования объекта в FormData.
 * Используется для отображения списка блокировок пользователя или компании
 */
export function handleConvertBlocksReqBody(params: GetListParams): FormData {
  const {filter, pagination, sort} = params;

  const reqBody = {
    entity: filter?.entity || "",
    userId: filter?.userId || "",
    companyId: filter?.companyId || "",
    action: filter?.action || "",
    limit: pagination?.perPage || 10,
    page: pagination?.page || 1,
    sortBy: replaceSortForBlocks(sort?.field) || "",
    sortAsc: sort?.order === "ASC",
    createdById: "",
  };

  return handleTransformToFormData(reqBody);
}

export function handleConvertOneUserBlocksInCompanyReqBody(
  params: GetOneParams,
): FormData {
  const {id, meta} = params;

  const reqBody = {
    userId: id,
    companyId: "",
    action: "BLOCK",
    limit: 50,
    page: 1,
    sortBy: "",
    sortAsc: "",
    updatedById: "",
    createdById: "",
    cancelled: null,
  };

  return handleTransformToFormData(reqBody);
}

export function handleConvertBlocksInCompanyReqBody(params: GetListParams): FormData {
  const {filter, pagination, sort} = params;

  const reqBody = {
    userId: filter?.userId || "",
    companyId: filter?.companyId || "",
    action: "BLOCK",
    limit: pagination?.perPage || 10,
    page: pagination?.page || 1,
    sortBy: replaceSortForBlocks(sort?.field) || "",
    sortAsc: sort?.order === "ASC",
    updatedById: "",
    createdById: "",
    cancelled: filter?.cancelled !== undefined ? filter?.cancelled : null,
  };

  return handleTransformToFormData(reqBody);
}

export function handleConvertOneBlockReqBody(params: GetOneParams): FormData {
  const {id, meta} = params;

  const reqBody = {
    entity: meta?.entity || "",
    userId: meta?.entity === "USER" ? id : "",
    companyId: meta?.entity === "COMPANY" ? id : "",
    action: "WARN",
    limit: 10,
    page: 1,
    sortBy: "",
    sortAsc: "",
    createdById: "",
  };

  return handleTransformToFormData(reqBody);
}

export function handleConvertOneUserBlockReqBody(params: GetOneParams): FormData {
  const {id, meta} = params;

  const reqBody = {
    entity: "",
    userId: id,
    companyId: "",
    action: "",
    limit: 50,
    page: 1,
    sortBy: "",
    sortAsc: "",
    createdById: "",
  };

  return handleTransformToFormData(reqBody);
}

/**
 * Функция для преобразования объекта в FormData.
 * Используется для отображения листа пользователей
 */
export function handleConvertUsersReqBody(params: GetListParams): FormData {
  const {filter, pagination, sort} = params;

  const isActive = transformFalseToNull((filter?.hide || []).includes("active"));

  const reqBody = {
    companyId: filter?.companyId || "",
    userId: filter?.userId || "",
    search: filter?.search || "",
    limit: pagination?.perPage || 10,
    page: pagination?.page || 1,
    sortBy: replaceSortForUsers(sort?.field) || "",
    sortAsc: sort?.order === "ASC",
    sortBlockedLast: (filter?.hide || []).includes("sortBlockedLast"),
    isInCompany: typeof filter.isInCompany === "boolean" ? filter.isInCompany : null,

    active: isActive ? false : null,
    dismissed: transformFalseToNull((filter?.hide || []).includes("dismissed")),
    blockedInCompany: transformFalseToNull(
      (filter?.hide || []).includes("blockedInCompany"),
    ),

    roles: false,
    permissions: false,
  };

  return handleTransformToFormData(reqBody);
}

export function handleConvertSalesUsersReqBody(params: GetListParams): FormData {
  const {filter, pagination, sort} = params;

  const choices = filter.hide || [];

  const hasCompany = choices.includes("hasCompany");
  const noCompany = choices.includes("noCompany");
  const withoutOurs = choices.includes("withoutOurs");

  const isInCompany =
    hasCompany && noCompany ? null : hasCompany ? true : noCompany ? false : null;

  const reqBody = {
    companyId: filter?.companyId || "",
    userId: filter?.userId || "",
    search: filter?.search || "",
    limit: pagination?.perPage || 10,
    page: pagination?.page || 1,
    sortBy: replaceSortForUsers(sort?.field) || "",
    sortAsc: sort?.order === "ASC",
    sortBlockedLast: null,
    isInCompany,
    active: null,
    dismissed: null,
    blockedInCompany: null,
    roles: false,
    permissions: false,
    sales: true,
    exclude_labels: withoutOurs ? ["Свой"] : [],
  };

  return handleTransformToFormData(reqBody);
}

export function handleConvertOneUserReqBody(params: GetOneParams): FormData {
  const {id, meta} = params;

  const reqBody = {
    companyId: "",
    userId: id,
    search: "",
    limit: 1,
    page: 1,
    sortBy: "",
    sortAsc: "",
    sortBlockedLast: null,
    active: null,
    dismissed: null,
    blockedInCompany: null,
    roles: true,
    permissions: true,
  };

  return handleTransformToFormData(reqBody);
}

const transformFalseToNull = (value: boolean) => (value ? value : null);

const transformStringToValue = (value: "null" | "true" | "false"): null | boolean => {
  if (value === "null") {
    return null;
  } else if (value === "true") {
    return true;
  } else if (value === "false") {
    return false;
  }
  return null; // возвращаем значение по умолчанию
};

/**
 * Функция для преобразования объекта в FormData.
 * Используется для отображения листа компаний
 *
 * Поля сustomer, supplier, producer - если передавать false то приходит (ТОЛЬКО НЕ )
 *  * для отображения всех и игнорирования поля фильтрации с отрицанием используется null
 */
export function handleConvertCompanyReqBody(params: GetListParams): FormData {
  const {filter, pagination, sort} = params;

  const filtersHide = filter?.hide || [];

  const isCustomer = filtersHide.includes("customer");
  const isSupplier = filtersHide.includes("supplier");
  const isProducer = filtersHide.includes("producer");
  const isAll = !filtersHide.includes("only");
  // || (!isCustomer && !isSupplier && !isProducer);

  const reqBody = {
    companyId: filter?.companyId || "",
    search: filter?.search || "",
    limit: pagination?.perPage || 10,
    page: pagination?.page || 1,
    sortBy: replaceSortForCompanies(sort?.field) || "",
    sortAsc: sort?.order === "ASC",
    customer: isAll ? transformFalseToNull(isCustomer) : isCustomer,
    supplier: isAll ? transformFalseToNull(isSupplier) : isSupplier,
    producer: isAll ? transformFalseToNull(isProducer) : isProducer,
  };

  return handleTransformToFormData(reqBody);
}

export function handleConvertSalesCompanyReqBody(params: GetListParams): FormData {
  const {filter, pagination, sort} = params;

  let customer: true | null = null;
  let supplier: true | null = null;
  let not_seen_in_days: number | null = null;
  let seen_in_days: number | null = null;

  const isActiveByStatus = filter.isActiveByStatus;

  const statusMap: Record<
    string,
    {
      customer: true | null;
      supplier: true | null;
      seen: number | null;
      notSeen: number | null;
    }
  > = {
    isActiveCustomer: {customer: true, supplier: null, seen: 5, notSeen: null},
    nonActiveCustomer: {customer: true, supplier: null, seen: null, notSeen: 5},
    isActiveSupplier: {customer: null, supplier: true, seen: 5, notSeen: null},
    nonActiveSupplier: {customer: null, supplier: true, seen: null, notSeen: 5},
  };

  const status = statusMap[isActiveByStatus];
  if (status) {
    customer = status.customer;
    supplier = status.supplier;
    seen_in_days = status.seen;
    not_seen_in_days = status.notSeen;
  }

  const reqBody = {
    companyId: filter?.companyId || "",
    search: filter?.search || "",
    limit: pagination?.perPage || 10,
    page: pagination?.page || 1,
    sortBy: replaceSortForCompanies(sort?.field) || "",
    sortAsc: sort?.order === "ASC",
    hasPaidInvoices: filter?.hasPaidInvoices ?? null,

    producer: null,
    customer,
    supplier,
    not_seen_in_days,
    seen_in_days,
  };

  return handleTransformToFormData(reqBody);
}

/**
 * Функция для генерации объекта FormData на основе данных новости.
 * @param {any} item - Объект с данными новости.
 * @returns {FormData} Возвращает объект FormData с данными новости.
 */
export function generateFDForNews(item: any): FormData {
  const FD = new FormData();
  FD.append("preview_file", item?.preview_file?.rawFile || false);
  FD.append("html", item?.html);
  FD.append("id", item?.id);
  FD.append("remove_photo", item?.remove_photo);
  FD.append("status", item?.status);
  FD.append("time", item?.time instanceof Date ? item?.time.toISOString() : item?.time);
  FD.append("title", item?.title);

  return FD;
}

export const defaultParams = {
  filter: {hidev3: ["all"]} as any,
  pagination: {} as any,
  sort: {} as any,
};

export function handleConvertPaymentsListReqBody(params: GetListParams): string {
  const {filter, pagination, sort} = params;

  const reqBody = {
    limit: pagination?.perPage || 10,
    page: pagination?.page || 1,
    payment_order_asc: sort?.order === "ASC",
    only_paid: !!filter?.only_paid,
    linked_to_deals: !!filter?.linked_to_deals,
  };

  return qs.stringify(reqBody);
}

export function handleConvertAllDealsListReqBody(params: GetListParams): string {
  const {filter, pagination, sort} = params;

  const reqBody = {
    limit: pagination?.perPage || 10,
    page: pagination?.page || 1,
    order_asc: sort?.order === "ASC",
    search: filter?.search || "",
    customer_inn: filter?.customer_inn || "",
    supplier_inn: filter?.supplier_inn || "",
  };

  return qs.stringify(reqBody);
}

export function handleConvertPromoGetListReqBody(params: GetListParams): FormData {
  const {filter, pagination, sort} = params;

  const reqBody = {
    limit: pagination?.perPage || 10,
    page: pagination?.page || 1,
    sortBy: replaceSortForPromoList(sort?.field) || "",
    sortAsc: sort?.order === "ASC",
    search: filter?.search || "",
    companyId: filter?.companyId || "",
    promoId: filter?.promoId || "",
  };

  return handleTransformToFormData(reqBody);
}

export function handleConvertInvoiceGetListReqBody(params: GetListParams): FormData {
  const {filter, pagination, sort} = params;

  const reqBody = {
    limit: pagination?.perPage || 10,
    page: pagination?.page || 1,
    companyId: filter?.companyId || "",
    invoiceId: filter?.invoiceId || "",
    isPaid: filter?.isPaid,
    search: filter?.search || "",
    sortBy: replaceSortForInvoicesList(sort?.field) || "",
    sortAsc: sort?.order === "ASC",
  };

  return handleTransformToFormData(reqBody);
}

export function handleConvertTariffGetListReqBody(params: GetListParams): FormData {
  const {filter, pagination, sort} = params;

  const reqBody = {
    promo: filter?.promo,
    archived: false,
  };

  return handleTransformToFormData(reqBody);
}

export function handleConvertAssetsGetListReqBody(params: GetListParams): FormData {
  const {filter, pagination, sort} = params;

  const reqBody = {
    search: filter?.search || "",
    limit: pagination?.perPage || 10,
    page: pagination?.page || 1,
    companyId: filter?.companyId || "",
    assetId: filter?.assetId || "",
    sortBy: replaceSortForAssetsList(sort?.field) || "",
    sortAsc: sort?.order === "ASC",
  };

  return handleTransformToFormData(reqBody);
}

export function handleConvertCreateBlogToFD(data: any): FormData {
  const reqBody = {
    card_title: data?.card_title || "",
    position: data?.position || "",
    html: data?.html || "",
    video_url: data?.video_url || "",
    status: data?.status || false,
    remove_photo: data?.remove_photo || "",
    title: data?.title || "",
    sub_title: data?.sub_title || "",
    type: data?.type || "",
  };

  const FD = handleTransformToFormData(reqBody);
  FD.append("preview_file", data?.preview_file?.rawFile || false);
  return FD;
}
