import {DataProvider, HttpError, Identifier} from "react-admin";
import {API_URL} from "shared/constants/apiURL";
import axios from "axios";
import {generateFDForNews} from "../utils/helpers";
import {defaultProvider} from "./defaultProvider";
import {HTTP_413} from "shared/constants/ROUTES";

export const newsProvider = {
  ...defaultProvider(),
  update: async (resource, params) => {
    const FD = generateFDForNews(params?.data);
    try {
      const data = (
        await axios(`${API_URL}/${resource}/${params.id}`, {
          method: "PUT",
          headers: {"Content-Type": "multipart/form-data"},
          data: FD,
        })
      )?.data;

      return {data: {...params.data, ...data}};
    } catch (err) {
      const errBody = err.response.data.detail;

      if (err.response.status === HTTP_413) {
        throw new HttpError("Произошла ошибка", err.response.status, {
          errors: {preview_file: "Слишком большой размер"},
        });
      }

      throw new HttpError("Произошла ошибка", err.response.status, {errors: errBody});
    }
  },

  create: async (resource, params) => {
    const FD = generateFDForNews(params?.data);

    try {
      const data = (
        await axios(`${API_URL}/${resource}`, {
          method: "POST",
          headers: {"Content-Type": "multipart/form-data"},
          data: FD,
        })
      )?.data;

      return {data: {...data, ...params.data}};
    } catch (err) {
      const errBody = err.response.data.detail;

      if (err.response.status === HTTP_413) {
        throw new HttpError("Произошла ошибка", err.response.status, {
          errors: {preview_file: "Слишком большой размер"},
        });
      }

      throw new HttpError("Произошла ошибка", err.response.status, {errors: errBody});
    }
  },

  delete: async (resource = "", params) => {
    return await axios(`${API_URL}/${resource}/${params.id}`, {
      method: "DELETE",
      headers: {"Content-Type": "multipart/form-data"},
    });
  },

  updateMany: async (resource, params) => {
    const ids: Identifier[] = [];

    try {
      for (const item of params.data.items) {
        const FD = generateFDForNews({...item, status: !item?.status});

        await axios(`${API_URL}/${resource}/${item.id}`, {
          method: "PUT",
          headers: {"Content-Type": "multipart/form-data"},
          data: FD,
        });
        ids.push(item.id);
      }
      return {data: ids};
    } catch (e) {
      return {data: ids};
    }
  },

  deleteMany: async (resource, params) => {
    const ids: Identifier[] = [];
    try {
      for (const id of params.ids) {
        await axios(`${API_URL}/${resource}/${id}`, {
          method: "DELETE",
          headers: {"Content-Type": "multipart/form-data"},
        });
        ids.push(id);
      }
      return {data: ids};
    } catch (e) {
      console.log(e);
      return {data: ids};
    }
  },
} as DataProvider;
