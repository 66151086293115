import {
  Datagrid,
  List,
  TextField,
  TextInput,
  useListContext,
  DateField,
  minLength,
  Filter,
  FunctionField,
  WrapperField,
  SimpleForm,
  NullableBooleanInput,
} from "react-admin";
import {getDataGridStyles} from "shared/styles/dataGridStyles";
import {CompanyInfoAdmin} from "typesFromApi/types/companyApi";
import Loader from "shared/components/Loader/Loader";
import {
  Box,
  InputAdornment,
  Tooltip,
  Typography,
  Stack,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import {differenceInDays, startOfDay} from "date-fns";
import {PACKAGES_NAME} from "shared/constants/packagesName";
import {useEffect} from "react";
import CheckIcon from "@mui/icons-material/Check";
import {InvoiceListForSales} from "../components/InvoiceListForSales";
import {CommentSection} from "../components/CommentSection";

const CustomFilter = (props) => {
  const {setFilters, filterValues, displayedFilters, showFilter, total} =
    useListContext();

  const handleCheckboxChange = (id) => {
    const newValue = filterValues.isActiveByStatus === id ? "" : id;
    setFilters({...filterValues, isActiveByStatus: newValue}, null, false);
  };

  useEffect(() => {
    if (!displayedFilters.isActiveByStatus) {
      showFilter("isActiveByStatus", "");
    }
  }, [displayedFilters, showFilter]);

  return (
    <Filter {...props}>
      <TextInput
        source="search"
        resettable
        label={"Поиск"}
        alwaysOn
        validate={minLength(3)}
        InputProps={{
          endAdornment: (
            <InputAdornment position={"end"}>
              <Tooltip
                title={
                  <>
                    Поиск осуществляется при длине больше 3ех символов.
                    <br />
                    По частичному совпадению: название компании, телефона, email.
                    <br />
                    По полному совпадению: ИНН, КПП, ОГРН, companyId.
                  </>
                }
              >
                <LiveHelpIcon sx={{cursor: "help"}} />
              </Tooltip>
            </InputAdornment>
          ),
        }}
        sx={{maxWidth: "600px", width: "100%", minWidth: "500px"}}
      />
      <NullableBooleanInput
        label="Оплата"
        source="hasPaidInvoices"
        alwaysOn
        sx={{minWidth: "200px"}}
      />

      {/* <NullableBooleanInput
          label="Состояние"
          source="isActive"
          alwaysOn
          trueLabel="Активные"
          falseLabel="Спящие"
          sx={{minWidth: "200px"}}
        /> */}

      <FunctionField
        source="isActiveByStatus"
        render={(record) => (
          <Box
            sx={{display: "flex", flexWrap: "wrap", my: 0, ml: 4, alignItems: "center"}}
            key="isActiveByStatus"
          >
            {[
              {id: "isActiveCustomer", name: "Активные заказчики"},
              {id: "nonActiveCustomer", name: "Спящие заказчики"},
              {id: "isActiveSupplier", name: "Активные поставщики"},
              {id: "nonActiveSupplier", name: "Спящие поставщики"},
            ].map((choice) => {
              const isChecked = filterValues.isActiveByStatus === choice.id;
              return (
                <FormControlLabel
                  key={choice.id}
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={() => handleCheckboxChange(choice.id)}
                      size="small"
                      sx={{height: "32px"}}
                    />
                  }
                  label={
                    <span>
                      {choice.name}{" "}
                      {isChecked ? (
                        <span
                          style={{
                            minWidth: "20px",
                            maxWidth: "20px",
                            display: "inline-block",
                          }}
                        >{`${total > 99 ? 99 : total || ""}`}</span>
                      ) : (
                        <span style={{width: "20px", display: "inline-block"}}></span>
                      )}
                    </span>
                  }
                />
              );
            })}
          </Box>
        )}
      />
    </Filter>
  );
};

export const SalesCompanies = () => {
  return (
    <List
      sort={{field: "createdAt", order: "DESC"}}
      perPage={25}
      filters={<CustomFilter />}
      title="Список компании"
      actions={false}
      sx={{
        ".RaFilterFormInput-hideButton": {display: "none"},
        ".RaList-actions": {minHeight: 48},
      }}
      resource="sales-cabinet/companies"
    >
      <Fields />
    </List>
  );
};

function Fields() {
  const {isLoading = false} = useListContext<CompanyInfoAdmin>();

  if (isLoading) return <Loader />;

  return (
    <>
      <Datagrid
        bulkActionButtons={false}
        sx={({palette}) => ({
          ...getDataGridStyles(palette),
        })}
        rowClick={"expand"}
        expand={<RowDetail />}
        expandSingle
      >
        <TextField source="name" label={"Компания"} textAlign="left" />

        <WrapperField
          source={"createdAt"}
          label="Дата регистрации"
          textAlign="center"
          sortable={true}
        >
          <DateField
            source={"createdAt"}
            label={
              <>
                Дата <br /> регистрации
              </>
            }
            textAlign="center"
          />
        </WrapperField>

        <FunctionField
          source={"lastSeen"}
          label={"Состояние"}
          textAlign="center"
          render={(record) => {
            if (!record || !record.lastSeen) {
              return null;
            }

            const lastSeenDate = startOfDay(new Date(record.lastSeen));

            const daysDifference = differenceInDays(new Date(), lastSeenDate);
            const isActive = daysDifference <= 5;

            return (
              <Box
                display="inline-flex"
                alignItems="center"
                justifyContent="center"
                sx={({palette}) => ({
                  color: isActive ? palette.green.main : palette.red.main,
                })}
              >
                {isActive ? "Активный" : "Спящий"}
              </Box>
            );
          }}
          sortable={false}
        />

        <DateField
          source={"lastSeen"}
          label={
            <>
              Дата последней <br /> активности
            </>
          }
          textAlign="center"
          sortable={false}
        />

        <FunctionField
          source={"lastSeen"}
          label={
            <>
              Дней <br /> без активности
            </>
          }
          textAlign="center"
          render={(record) => {
            if (!record || !record.lastSeen) {
              return null;
            }

            const lastSeenDate = new Date(record.lastSeen);
            const daysDifference = differenceInDays(new Date(), lastSeenDate);

            return daysDifference;
          }}
          sortable={false}
        />

        <FunctionField
          source="status"
          label="Статус"
          render={(record) => {
            if (!record) return null;

            const statuses: string[] = [];
            if (record.customer) statuses.push("Заказчик");
            if (record.supplier) statuses.push("Поставщик");
            if (record.producer) statuses.push("Производитель");

            return statuses.length > 0 ? (
              <Stack alignItems={"flex-start"}>
                {statuses.map((status, idx) => (
                  <Typography variant="body2" key={idx}>
                    {status}
                  </Typography>
                ))}
              </Stack>
            ) : (
              "-"
            );
          }}
          textAlign="left"
          sortable={false}
        />

        <FunctionField
          source="subscriptions"
          label="Подписки"
          render={(record) => {
            if (!record || !record.subscriptions || record.subscriptions.length === 0) {
              return null;
            }

            return (
              <Box display="flex" flexDirection="column" alignItems={"flex-start"}>
                {record.subscriptions.map((sub) => {
                  if (sub.code === "SRM_ACCESS") return null;

                  const name = PACKAGES_NAME[sub.code];
                  const activeTo = sub.activeTo
                    ? new Date(sub.activeTo).toLocaleDateString()
                    : "-";

                  return (
                    <Box
                      key={sub.code}
                      display="flex"
                      justifyContent="flex-start"
                      gap={1}
                      alignItems={"center"}
                    >
                      <Typography variant="body2" sx={{whiteSpace: "nowrap"}}>
                        {name}{" "}
                      </Typography>
                      <Typography variant="body2" sx={{whiteSpace: "nowrap"}}>
                        до {activeTo}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            );
          }}
          textAlign="left"
          sortable={false}
        />

        <FunctionField
          source="invoices"
          label="Оплата"
          render={(record) => {
            return record?.invoices &&
              record?.invoices.filter(({isPaid}) => !!isPaid).length ? (
              <CheckIcon />
            ) : null;
          }}
          textAlign="center"
          sortable={false}
        />

        <FunctionField
          source="salesHelper"
          label="Комментарий"
          textAlign="left"
          sortable={false}
          render={(record) => {
            if (!record || !record.salesHelper) return null;

            const {internalComment, needAction, needActionDate} = record.salesHelper;

            const formattedDate = needActionDate
              ? new Date(needActionDate).toLocaleDateString()
              : null;

            return (
              <Box>
                {internalComment && (
                  <Typography
                    variant="body2"
                    sx={({palette}) => ({
                      color: needAction ? palette.orange.main : palette.text.primary,
                    })}
                  >
                    {internalComment}
                  </Typography>
                )}

                {formattedDate && (
                  <Typography variant="body2" color="textSecondary">
                    {formattedDate}
                  </Typography>
                )}
              </Box>
            );
          }}
        />
      </Datagrid>
    </>
  );
}

function RowDetail() {
  return (
    <SimpleForm
      sx={{p: 0, ">div": {gap: 3}, mt: 4, px: 8, width: "100%"}}
      toolbar={false}
    >
      <Stack direction={"row"} alignItems={"center"} gap={4}>
        <Stack direction={"row"} gap={2} alignItems={"center"}>
          <Typography sx={{fontWeight: 600}}>ИНН: </Typography>
          <TextField source="inn" label={"ИНН"} textAlign="center" />
        </Stack>

        <Stack direction={"row"} gap={2} alignItems={"center"}>
          <Typography sx={{fontWeight: 600}}>Владелец: </Typography>
          <TextField
            source="topManager[0].fioShort"
            label={"Владелец"}
            textAlign="center"
            sortable={false}
          />
        </Stack>

        <Stack direction={"row"} gap={2} alignItems={"center"}>
          <Typography sx={{fontWeight: 600}}>Телефон: </Typography>
          <TextField source="phoneNumber" label={"Телефон"} textAlign="center" />
        </Stack>
      </Stack>
      <Stack sx={{width: "100%", my: 6}}>
        <CommentSection commentKey="companyId" />
      </Stack>

      <Stack sx={{width: "100%"}}>
        <InvoiceListForSales />
      </Stack>
    </SimpleForm>
  );
}
