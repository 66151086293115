import {BooleanField, BooleanFieldProps} from "react-admin";
import RemoveIcon from "@mui/icons-material/Remove";

export const MyBooleanField = (props: BooleanFieldProps<any>) => {
  const {sx, ...rest} = props;
  return (
    <BooleanField
      {...rest}
      FalseIcon={props.FalseIcon === null ? null : RemoveIcon}
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        ...sx,
      }}
      textAlign="center"
    />
  );
};
