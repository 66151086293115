import {format} from "date-fns";
import {
  useRecordContext,
  useList,
  ListContextProvider,
  Datagrid,
  FunctionField,
  NumberField,
} from "react-admin";
import {MyBooleanField} from "shared/components/MyBooleanField/MyBooleanField";
import {PATTERN_DATE_TIME_FULL, PATTERN_DATE_SHORT} from "shared/constants/dates";
import {getDataGridStyles} from "shared/styles/dataGridStyles";

export const InvoiceListForSales = () => {
  const record = useRecordContext();
  const listContext = useList({data: record?.invoices || []});

  return (
    <ListContextProvider value={listContext}>
      <InvoicesFieldsForSales />
    </ListContextProvider>
  );
};

function InvoicesFieldsForSales() {
  return (
    <Datagrid
      bulkActionButtons={false}
      sx={({palette}) => ({
        ...getDataGridStyles(palette),
      })}
      empty={<></>}
    >
      <FunctionField
        label="№ счета"
        render={(record) =>
          `${record.numPrefix || ""}${record.num || ""}${record.numSuffix || ""}`
        }
        sortable={false}
      />

      <FunctionField
        label="Создан"
        source="createdAt"
        render={(record) =>
          record?.createdAt ? format(record.createdAt, PATTERN_DATE_TIME_FULL) : null
        }
        sortable={false}
      />

      <MyBooleanField label="Оплачен" source="isPaid" sortable={false} FalseIcon={null} />

      <FunctionField
        label="Дата оплаты"
        source="paidDate"
        render={(record) =>
          record?.paidDate ? format(record.paidDate, PATTERN_DATE_SHORT) : null
        }
        sortable={false}
      />

      <NumberField label="Сумма" source="totalAmount" textAlign="left" sortable={false} />

      <FunctionField
        label="Тариф"
        source="purchases"
        render={(record) =>
          record?.purchases ? record?.purchases.map((it) => it.alias).join(", ") : null
        }
        sortable={false}
      />
    </Datagrid>
  );
}
