import Stack from "@mui/material/Stack";
import axios from "axios";
import {BooleanInput, useNotify, useRecordContext, useRefresh} from "react-admin";
import {API_URL} from "shared/constants/apiURL";

const LABEL_OURS = "Свой";

const handleUpdateUsersLabel = async ({
  userId,
  checked,
}: {
  userId: string;
  checked: boolean;
}) => {
  const route = checked ? "add-label-to-user" : "remove-label-from-user";
  const fd = Object.entries({label: LABEL_OURS, userId}).reduce(
    (formData, [key, value]) => {
      formData.append(key, `${value ?? ""}`);
      return formData;
    },
    new FormData(),
  );
  await axios.post(`${API_URL}/users/v2/admin/${route}`, fd);
};

export const UserLabelSection = () => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();

  const userId = (record?.id as string) || "";

  const handleSetLabel = async (e) => {
    const checked = e.target.checked;
    try {
      await handleUpdateUsersLabel({checked, userId});
      refresh();
    } catch (err) {
      notify("Произошла ошибка", {type: "error"});
      return;
    }
  };

  return (
    <BooleanInput
      source="labels"
      checked={(record.labels || []).includes(LABEL_OURS)}
      label={LABEL_OURS}
      onChange={handleSetLabel}
      helperText={false}
      sx={{
        ".MuiSwitch-root": {
          height: "28px",
          pt: "7px",
          pb: "7px",
        },
        ".MuiSwitch-switchBase": {
          pt: 1,
          pb: 1,
        },
      }}
    />
  );
};
